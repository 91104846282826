<template>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <!-- Home -->
            <li class="nav-item dropdown">
              <router-link
                to="/home"
                class="nav-link dropdown-toggle arrow-none"
                title="Home"
              >
                <i class="bx ri-home-line mr-2"></i>
                Home
              </router-link>
            </li>
            <!-- Administração -->
            <li v-if="getModuleUserByCode('0')" class="nav-item dropdown">
              <a
                href="javascript: void(0);"
                class="nav-link dropdown-toggle arrow-none"
                role="button"
                @click="onMenuClick"
              >
                <i class="bx ri-user-settings-line mr-2"></i>
                Administração
                <div class="arrow-down"></div>
              </a>
              <div class="dropdown-menu row" aria-labelledby="topnav-dashboard">
                <template v-if="getModuleUserByCode('0.01')">
                  <router-link
                    to="/administration/users"
                    class="col dropdown-item side-nav-link-ref"
                    title="Usuários"
                  >
                    Usuários
                  </router-link>
                </template>
              </div>
            </li>
            <!-- BackOffice -->
            <li v-if="getModuleUserByCode('1')" class="nav-item dropdown">
              <a
                href="javascript: void(0);"
                class="nav-link dropdown-toggle arrow-none"
                role="button"
                @click="onMenuClick"
              >
                <i class="bx ri-settings-2-line mr-2"></i>
                BackOffice
                <div class="arrow-down"></div>
              </a>
              <div class="dropdown-menu row" aria-labelledby="topnav-dashboard">
                <template v-if="getModuleUserByCode('1.01')">
                  <router-link
                    to="/backoffice/clients"
                    class="col dropdown-item side-nav-link-ref"
                    title="Clientes"
                  >
                    Clientes
                  </router-link>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavbarHorizontal',

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    onMenuClick(event) {
      event.preventDefault()
      const nextEl = event.target.nextSibling
      if (nextEl && !nextEl.classList.contains('show')) {
        const parentEl = event.target.parentNode
        if (parentEl) {
          parentEl.classList.remove('show')
        }
        nextEl.classList.add('show')
      } else if (nextEl) {
        nextEl.classList.remove('show')
      }
      return false
    },
  },

  mounted() {
    const links = document.getElementsByClassName('side-nav-link-ref')
    let matchingMenuItem = null

    for (let i = 0; i < links.length; i += 1) {
      if (
        window.location.pathname === links[i].pathname ||
        window.location.pathname.includes(links[i].pathname)
      ) {
        matchingMenuItem = links[i]
        break
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active')
      const parent = matchingMenuItem.parentElement
      if (parent) {
        parent.classList.add('active')
        const parent2 = parent.parentElement
        if (parent2) {
          parent2.classList.add('active')
        }
        const parent3 = parent2.parentElement
        if (parent3) {
          parent3.classList.add('active')
          const childAnchor = parent3.querySelector('.has-dropdown')
          if (childAnchor) childAnchor.classList.add('active')
        }

        const parent4 = parent3.parentElement
        if (parent4) parent4.classList.add('active')
        const parent5 = parent4.parentElement
        if (parent5) parent5.classList.add('active')
      }
    }
  },
}
</script>
