<template>
  <b-modal
    id="modalAccount"
    title="Minha Conta"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="updatePassword">
      <div class="row">
        <div class="col-sm-12">
          <p><strong>Nome:</strong> {{ user.name }}</p>
        </div>
        <div class="col-sm-12">
          <p><strong>E-mail:</strong> {{ user.email }}</p>
        </div>
        <div class="col-sm-12">
          <p class="mb-0"><strong>Telefone:</strong> {{ user.phone }}</p>
        </div>
      </div>
      <hr />
      <h5>Alterar Senha</h5>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Senha Antiga: <span class="text-danger">*</span></label>
          <input
            type="password"
            class="form-control"
            :class="{ 'is-invalid': $v.oldPassword.$error }"
            placeholder="Senha Antiga"
            v-model.trim="$v.oldPassword.$model"
          />
          <span v-if="!$v.oldPassword.minLength" class="invalid-feedback">
            A senha deve ter pelo menos
            {{ $v.oldPassword.$params.minLength.min }} caracteres
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Nova Senha: <span class="text-danger">*</span></label>
          <input
            type="password"
            class="form-control"
            :class="{ 'is-invalid': $v.password.$error }"
            placeholder="Nova Senha"
            v-model.trim="$v.password.$model"
          />
          <span v-if="!$v.password.minLength" class="invalid-feedback">
            A senha deve ter pelo menos
            {{ $v.password.$params.minLength.min }} caracteres
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>
            Confirme a Nova Senha: <span class="text-danger">*</span>
          </label>
          <input
            type="password"
            class="form-control"
            :class="{ 'is-invalid': $v.confirmPassword.$error }"
            placeholder="Confirme a Nova Senha"
            v-model.trim="$v.confirmPassword.$model"
          />
          <span
            v-if="!$v.confirmPassword.sameAsPassword"
            class="invalid-feedback"
          >
            As senhas devem ser idênticas
          </span>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          title="Atualizar"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>Atualizar</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'AccountModal',

  data() {
    return {
      userId: this.$store.getters.user.id,
      user: {
        name: '',
        email: '',
        phone: '',
      },
      oldPassword: '',
      password: '',
      confirmPassword: '',
      disableButton: false,
    }
  },

  methods: {
    async getUser() {
      try {
        this.user = await this.$store.dispatch('getUser', this.userId)
        this.$bvModal.show('modalAccount')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async updatePassword() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch('updatePassword', {
            userId: this.userId,
            oldPassword: this.oldPassword,
            password: this.password,
            confirmPassword: this.confirmPassword,
          })
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', false)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    oldPassword: {
      required,
      minLength: minLength(6),
    },
    password: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      sameAsPassword: sameAs('password'),
    },
  },

  mounted() {
    this.getUser()
  },
}
</script>
