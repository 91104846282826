<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner">
          <i class="ri-loader-line spin-icon" />
        </div>
      </div>
    </div>
    <div id="layout-wrapper">
      <Topbar />
      <SidebarLeft
        :is-condensed="isMenuCondensed"
        :type="leftSidebarType"
        :width="layoutWidth"
      />
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <slot />
          </div>
        </div>
        <Footer />
      </div>
      <SidebarRight />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import router from '@/router'
import Topbar from '@/components/commons/Topbar.vue'
import Footer from '@/components/commons/Footer.vue'
import SidebarLeft from '@/components/commons/SidebarLeft.vue'
import SidebarRight from '@/components/commons/SidebarRight.vue'

export default {
  name: 'Vertical',

  components: {
    Topbar,
    Footer,
    SidebarLeft,
    SidebarRight,
  },

  data() {
    return {
      isMenuCondensed: false,
    }
  },

  computed: {
    ...mapGetters(['leftSidebarType', 'layoutWidth']),
  },

  methods: {
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable')

      if (window.screen.width >= 992) {
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
          document.body.classList.remove('vertical-collpsed')
        })
        document.body.classList.toggle('vertical-collpsed')
      } else {
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
        })
        document.body.classList.remove('vertical-collpsed')
      }
      this.isMenuCondensed = !this.isMenuCondensed
    },

    toggleRightSidebar() {
      document.body.classList.toggle('right-bar-enabled')
    },

    hideRightSidebar() {
      document.body.classList.remove('right-bar-enabled')
    },
  },

  mounted() {
    document.body.classList.remove('auth-body-bg')
    if (this.loader === true) {
      document.getElementById('preloader').style.display = 'block'
      document.getElementById('status').style.display = 'block'

      setTimeout(() => {
        document.getElementById('preloader').style.display = 'none'
        document.getElementById('status').style.display = 'none'
      }, 2500)
    } else {
      document.getElementById('preloader').style.display = 'none'
      document.getElementById('status').style.display = 'none'
    }
  },

  created: () => {
    document.body.removeAttribute('data-layout', 'horizontal')
    document.body.removeAttribute('data-topbar', 'dark')
    document.body.setAttribute('data-sidebar', 'dark')
  },
}
</script>
