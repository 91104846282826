<template>
  <div>
    <div v-click-outside="config" class="right-bar">
      <div data-simplebar class="h-100">
        <div class="rightbar-title px-3 py-4">
          <span
            class="right-bar-toggle float-right"
            role="button"
            @click="hide"
          >
            <i class="mdi mdi-close noti-icon"></i>
          </span>
          <h5 class="m-0">Configurações</h5>
        </div>
        <div class="p-3">
          <!-- Layout -->
          <h6 class="mb-0">Layout</h6>
          <hr class="mt-1" />
          <b-form-radio-group
            v-model="layout"
            stacked
            @input="changeType($event)"
          >
            <b-form-radio value="vertical" class="mb-1">Vertical</b-form-radio>
            <b-form-radio value="horizontal" class="mb-1">
              Horizontal
            </b-form-radio>
          </b-form-radio-group>
          <!-- Width -->
          <h6 class="mt-3">Width</h6>
          <hr class="mt-1" />
          <b-form-radio-group
            v-model="width"
            stacked
            @input="changeWidth($event)"
          >
            <b-form-radio value="fluid" class="mb-1">Fluid</b-form-radio>
            <b-form-radio value="boxed">Boxed</b-form-radio>
          </b-form-radio-group>
          <!-- Sidebar -->
          <div v-if="layout === 'vertical'">
            <h6 class="mt-3">Sidebar</h6>
            <hr class="mt-1" />
            <b-form-radio-group
              v-model="sidebarType"
              stacked
              @input="changeSidebar($event)"
            >
              <b-form-radio value="dark" class="mb-1">Dark</b-form-radio>
              <b-form-radio value="light" class="mb-1">Light</b-form-radio>
              <b-form-radio value="compact" class="mb-1">Compact</b-form-radio>
              <b-form-radio value="icon" class="mb-1">Icon</b-form-radio>
              <b-form-radio value="colored">Colored</b-form-radio>
            </b-form-radio-group>
          </div>
          <!-- Topbar -->
          <div v-if="layout === 'horizontal'">
            <h6 class="mt-3">Topbar</h6>
            <hr class="mt-1" />
            <b-form-radio-group
              v-model="topbar"
              stacked
              @input="changeTopbar($event)"
            >
              <b-form-radio value="dark" class="mb-1">Dark</b-form-radio>
              <b-form-radio value="light" class="mb-1">Light</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="rightbar-overlay"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SidebarRight',

  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ['click'],
      },
      layout: '',
      width: '',
      topbar: '',
      sidebarType: '',
    }
  },

  computed: {
    ...mapGetters([
      'layoutType',
      'layoutWidth',
      'topbarMode',
      'leftSidebarType',
    ]),
  },

  methods: {
    ...mapActions([
      'changeLayoutType',
      'changeLayoutWidth',
      'changeTopbarMode',
      'changeLeftSidebarType',
    ]),

    hide() {
      this.$parent.toggleRightSidebar()
    },

    handleRightBarClick(e, el) {
      this.$parent.hideRightSidebar()
    },

    // eslint-disable-next-line consistent-return
    middleware(event, el) {
      if (event.target.classList)
        return !event.target.classList.contains('toggle-right')
    },

    changeType(layout) {
      this.changeLayoutType({
        layoutType: layout,
      })
    },

    changeWidth(width) {
      return this.changeLayoutWidth({
        layoutWidth: width,
      })
    },

    changeTopbar(value) {
      return this.changeTopbarMode({
        topbarMode: value,
      })
    },

    changeSidebar(type) {
      return this.changeLeftSidebarType({
        leftSidebarType: type,
      })
    },
  },

  mounted() {
    this.layout = this.layoutType
    this.width = this.layoutWidth
    this.topbar = this.topbarMode
    this.sidebarType = this.leftSidebarType
  },
}
</script>
